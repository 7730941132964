<template>
  <div>
    <Header>
      <template v-if="token.length > 0">
        <i class="fa fa-trash" @click="onDeleteAll()"></i>
        <i class="fa fa-sign-out" @click="onSignOut()"></i>
      </template>
    </Header>
    <main>
      <Loader  v-if="false" />
      <template v-else>
        <template v-if="token.length == 0">
          <LoginForm  @btnClick="onTokenChange" />
        </template>
        <template v-else>
          <Alert v-if="error" :msg="error"/>
          <div class="slider-container">

            <template v-if="requestSwipeList.length>0">

              <div :class="{'back': true, 'visible': item.visible}"  v-for="item in requestSwipeList" :key="item.id">
                <swiper ref="swiper" :options="swiperOptions" @slideChange="onSlideChanged(item.id)">
                  <swiper-slide>
                    <div class="card-content">
                      <div class="time">{{formatDate(item.createdAt)}}</div>
                      <div class="artist">{{item.artist}}</div>
                      <div class="title">{{item.title}}</div>
                      <div class="text" v-if="item.text">{{item.text}}</div>
                      <div class="vote"><span>{{formatVote(item.vote)}}</span></div>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                  </swiper-slide>
                </swiper>
              </div>

            </template>
            <template v-else>
              <div class="no-entries">Keine Einträge vorhanden!</div>
            </template>

          </div>
        </template>
      </template>
    </main>
  </div>
</template>

<style>
main{
  padding-top: 60px;
}

.no-entries{
  padding: 2rem;
}
.slider-container{
  overflow-y: hidden;
  margin: auto;
  width: 95%;
  margin-bottom: 1rem;
  }
.card-content{background: #28282c; padding: 1.3rem 2rem; cursor: pointer; position: relative; }
.back{ background: var(--goass-color-green); border-radius: 10px; overflow: hidden;
  border: 1px solid #595962; position: relative; transition: opacity 200ms linear, margin-top 200ms linear; opacity: 0;
  position: relative; margin-top: 20px; z-index: 888;
  background: linear-gradient(90deg, var(--goass-color-bg-secondary) 45%, var(--goass-color-green) 100%);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.vote{
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: var(--goass-color-bg-secondary-accent);
  width: 50px;
  text-align: center;
  font-weight: bold;
  color: var(--goass-color-text);
  font-size: 1.3rem;
  vertical-align: middle;
  display: table-cell;
  transform: rotate(0deg);
}
.vote span{
  position: absolute;
  right: 0;
  left: 0;
  top: 50%;
  transform: translate(0%, -50%);
  line-height: 1;
  margin: 0;
  padding: 0;
}
.back.visible{ opacity: 1; margin-top: 10px; }
.back:after{
    content: "\f00c";
    position: absolute;
    right: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    font-family: "Font Awesome 5 Pro";
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.5rem;
}
.back > i.fa{
    position: absolute;
    left: -10px;
    z-index: 777;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.5rem;
}
.time{color: var(--goass-color-text); opacity: .8; padding-top: 0rem; font-size: .8rem;  }
.artist{color: var(--goass-color-text);  padding-top: 0.4rem; font-size: 1.1rem;  }
.title{color: var(--goass-color-primary); font-weight: bold; font-size: 1.4rem;}
.text{color: var(--goass-color-text);  padding-top: 0.4rem;  }

@media (min-width: 800px) {
  .slider-container{ width: 800px; }
}
</style>

<script>
import axios from 'axios';
import draggable from 'vuedraggable';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css';

import Header from '@/components/layouts/Header.vue';
import LoginForm from '@/components/layouts/LoginForm.vue';
import Loader from '@/components/elements/Loader.vue';
import Alert from '@/components/elements/Alert.vue';

export default {
  data() {
    return {
      token: '',
      error: '',
      swiperOptions: {},
      requestSwipeList: [],
    };
  },
  components: {
    Header,
    Loader,
    Alert,
    LoginForm,
    Swiper,
    SwiperSlide,
    draggable,
  },
  computed: {
    swiper() {
      return this.$refs.swiper.$swiper;
    },
  },
  methods: {
    onTokenChange(value) {
      this.token = value;
      window.localStorage.setItem('token', value);
      this.fetchData();
    },
    formatDate(date) {
      const createdAt = new Date(date);
      const today = new Date();

      if (createdAt.getDate() === today.getDate()
        && createdAt.getMonth() === today.getMonth()
        && createdAt.getFullYear() === today.getFullYear()) {
        return createdAt.toLocaleTimeString();
      }
      return createdAt.toLocaleString();
    },
    formatVote(count) {
      if (count.length > 2) {
        return '99+';
      }
      return count;
    },
    onSignOut() {
      this.token = '';
      window.localStorage.removeItem('token');
    },

    onDeleteAll() {
      this.requestSwipeList = [];
      this.remove();
    },
    async onSlideChanged(id) {
      await this.sleep(200);
      this.requestSwipeList = this.requestSwipeList.filter((i) => i.id !== id);
      this.remove(id);
    },
    async remove(id) {
      try {
        const instance = axios.create({
          baseURL: process.env.VUE_APP_GATEWAY_URL,
          headers: { Authorization: `Bearer ${this.token}` },
        });
        let path = '/dashboard/request';
        if (id !== undefined) path = `${path}/${id}`;
        const resp = await instance.delete(path);
        if (resp.status === 201) {
          console.log(resp.data);
          this.error = '';
        } else {
          this.error = `Eintrag konnte nicht entfernt werden! Status: ${resp.status})`;
        }
      } catch (err) {
        this.error = `Fehlerhafte Serververbindung! ${err}`;
      }
    },
    async add() {
      this.requestSwipeList.push({
        id: this.requestSwipeList.length + 1,
        title: 'Some title',
        description: 'some description',
        disabled: false,
        visible: false,
      });
      const index = this.requestSwipeList.length - 1;
      await this.sleep(200);

      this.requestSwipeList[index].visible = true;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async fetchData() {
      if (this.token.length === 0) { return; }
      try {
        const instance = axios.create({
          baseURL: process.env.VUE_APP_GATEWAY_URL,
          headers: { Authorization: `Bearer ${this.token}` },
        });
        const resp = await instance.get('/dashboard/request');

        if (resp.status === 200) {
          this.error = '';
          /*eslint-disable */
          const data = resp.data;

          if(data!=null && data.length>0){
            data.sort(function(a,b){
              return new Date(b.createdAt) - new Date(a.createdAt);
            });
          }

          this.requestSwipeList = [];
          for (var i = 0; i < data.length; i = i + 1) {
              this.requestSwipeList.push({
                id: data[i]._id,
                title: data[i].title,
                artist: data[i].artist,
                text: data[i].text,
                vote: data[i].vote,
                createdAt: data[i].createdAt,
                disabled: false,
                visible: true,
              });
          }
          /* eslint-enable */
        } else {
          this.error = `Fehlerhafte Server Anwort! (Status: ${resp.status})`;
        }
      } catch (error) {
        // Handle Error Here
        console.error(error);
        // alert(error.response.status);
        this.error = 'Problem mit der Verbindung zum Server!';
        if (error.response.status === 401) {
          this.error = 'Ungültige Authentifizierung';
          this.token = '';
        }
      }

      if (this.token.length === 0) { return; }
      setTimeout(this.fetchData, 10000);
    },
  },
  directives: {
    swiper: directive,
  },
  mounted() {
    this.token = window.localStorage.getItem('token');
    this.fetchData();
  },
};
</script>
