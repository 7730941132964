<template>
  <div id="login">
    <div class="login-container">
      <h1>Dashboard Login</h1>
      <input :disabled="isProcessing ? true : false" v-model="inputToken" placeholder="Password" v-on:keyup.enter="onInputEnter" maxlength="10"/>
      <button :class="{'loading': isProcessing}" @click="onBtnClick">Anmelden</button>
      <Alert v-if="error" :msg="error"/>
    </div>
  </div>
</template>

<style>
.login-container{
  width: 100%;
  margin: auto;
}
.login-container h1,
.login-container input,
.login-container button{
  width: 100%;
  padding: 0;
  margin: 0;
  line-height: 1;
  margin-top: .5rem;
  box-sizing: border-box;
  text-align: center;
}
.login-container h1{
  margin-top: 1rem;
  padding-top: 2rem;
  padding-bottom: .4rem;
  font-size: 1.5rem;
}
.login-container input,
.login-container button{
  padding: .8rem;
  font-size: 1.2rem;
  border-radius: 9px;
  border: 2px solid var(--goass-color-primary);
}
.login-container button{
  padding: .5rem;
}
.login-container input:focus,
.login-container input:active{
  border: 2px solid var(--goass-color-primary);
}
.login-container input{
  letter-spacing: 5px;
}
.login-container button{
  background: var(--goass-color-primary);
  color: white;
  cursor: pointer;
}
.login-container button.loading{
  background: var(--goass-color-bg-secondary);
  border-color: var(--goass-color-bg-secondary-accent);
}

@media (min-width: 300px) {
  .login-container{ width: 300px; }
}
</style>

<script>
import axios from 'axios';
import Alert from '@/components/elements/Alert.vue';

export default {
  data() {
    return {
      inputToken: '',
      isProcessing: false,
      error: '',
    };
  },
  components: {
    Alert,
  },
  methods: {
    onInputEnter() {
      this.onBtnClick();
    },
    onBtnClick() {
      if (this.inputToken.trim().length < 1 || this.isProcessing) return;
      this.inputToken = this.inputToken.trim();
      this.isProcessing = true;
      this.checkToken();
    },
    async checkToken() {
      this.error = '';
      try {
        const instance = axios.create({
          baseURL: process.env.VUE_APP_GATEWAY_URL,
          headers: { Authorization: `Bearer ${this.inputToken}` },
        });
        const resp = await instance.get('/dashboard/init');

        if (resp.status === 200) {
          this.isProcessing = false;
          this.$emit('btnClick', this.inputToken);
          return;
        }
      } catch (error) {
        if (error.response.status === 401) {
          this.error = 'Ungültiges Passwort';
        }
      }

      this.isProcessing = false;
      if (this.error.length === 0) this.error = 'Verbindungsfehler!';
    },
  },
  mounted() {
    this.error = '';
    this.isProcessing = false;
  },
};
</script>
