<template>
  <div :class="$style.container">
    {{msg}}
  </div>
</template>

<style module>
  .container{
    color: rgb(255, 63, 63);
    font-weight: bold;
    font-size: .95rem;
    padding: .7rem;
  }
</style>

<script>

export default {
  data() {
    return {
    };
  },
  props: {
    msg: String,
  },
  computed: {
  },
  methods: {
  },
  directives: {
  },
  mounted() {
  },
};
</script>
